@import "../scss/colors";
.video-section {
  padding: 50px 0;
  margin-bottom: 40px;
  text-align: center;
  background-color: $gallery;
  border-radius: 0 0 8px 8px;

  h2 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 18px;
    color: $dodger-blue;
    font-weight: 400;
    margin-bottom: 60px;
  }
  iframe {
    margin-bottom: 30px;
    max-width: 90%;
  }
}
