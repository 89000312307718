@import "../scss/sizes";
@import "../scss/colors";
.main-header {
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: $main-width;
  max-width: calc(100% - 32px);
  margin: 0 auto;
}
.logo {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
  color: $white-main;
  img {
    width: 80px;
    margin-right: 32px;
  }
}
.rtl {
  .logo {
    img {
      margin-left: 32px;
      margin-right: 0;
    }
  }
}
@media only screen and (max-width: 768px) {
  .main-header {
    width: calc(100% - 32px);
    margin: 0 16px 20px 16px;
  }
}
@media only screen and (max-width: 400px) {
  .logo {
    span {
      display: none;
    }
  }
}
