@import "../scss/colors";
@import "../scss/sizes";
.main-layout {
  position: relative;
  padding-top: $main-padding;
  background-color: $alto;
  padding-bottom: 120px;
  max-width: 100%;
  &.rtl {
    direction: rtl;
  }
  &:before {
    content: "";
    position: absolute;
    top: -$main-padding;
    height: 320px;
    width: 100%;
    background-color: $mountain_meadow;
  }
}
.main-layout-inner {
  position: relative;
  z-index: 2;
  width: $main-width;
  max-width: calc(100% - 32px);
  margin: 30px auto 0 auto;
  background-color: $alto;
  border-radius: $border-radius;
}
@media only screen and (max-width: 768px) {
  .main-layout-inner {
    width: calc(100% - 32px);
    margin: 0 16px;
  }
}
@media only screen and (max-width: 360px) {
  .main-layout-inner {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 0 10px;
  }
}
