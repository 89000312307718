@import "../scss/colors";

.MuiAccordion-root {
  margin-bottom: 16px !important;
  box-shadow: none !important;
  padding: 30px 0;
}
.MuiAccordionSummary-root {
  font-size: 20px;
  font-weight: 500;
}
.MuiAccordionDetails-root {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}
