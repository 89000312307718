@import "../scss/colors";
.main-section {
  display: flex;
  padding: 44px;
  background-color: $white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  > * {
    flex: 1;
  }
}
.main-section-left {
  max-width: 100%;
}
.main-input-container {
  label {
    display: block;
    margin-bottom: 13px;
  }
}
.main-input {
  display: flex;
  height: 62px;
  width: 400px;
  margin-bottom: 20px;
  &.enabled {
    * {
      cursor: pointer;
    }
  }
  input {
    flex: 3;
    height: 100%;
    padding-left: 20px;
    border-radius: 52px 0 0 52px;
    border: 0;
    background-color: $gallery;
    font-weight: 400;
    font-size: 18px;
    color: $mine_shaft;
  }
  button {
    border-radius: 0 52px 52px 0;
    flex: 1;
    height: 100%;
    font-size: 18px;
    font-weight: 400;
    background-color: $dodger-blue;
    color: $white;
  }
}

.main-content {
  margin-top: 45px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 19px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 6px;
    strong {
      font-weight: 500;
    }
    img {
      padding: 0 5px;
      &.main-content-setting-2-img {
        position: relative;
        top: 3px;
      }
    }
  }
}
.social-share {
  display: flex;
  justify-content: space-between;
  width: 420px;
  max-width: 100%;
  .social-share-whatsapp {
    width: 41px;
  }
}
.main-section-right {
  width: 100%;
  text-align: center;
}
.qr-inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: min(100%, 306px);
  height: 306px;

  margin: 16px auto;
  border-radius: 52px;
  background-color: $gallery;
}
.qr-status {
  position: relative;
  top: 4px;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 24px;
  &.yellow {
    background-color: $yellow;
  }
  &.green {
    background-color: $mountain_meadow;
  }
  &.red {
    background-color: $red;
  }
}
.rtl {
  .main-input {
    flex-direction: row-reverse;
  }
  .qr-status {
    margin-left: 12px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .main-section {
    flex-direction: column-reverse;
    align-items: center;
    padding: 44px 10px;
  }
  .main-input-container {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .main-input {
    width: 100%;
  }
}
