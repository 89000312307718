$mountain_meadow: #1dbe56;
$fun_green: #008a35;
$alto: #e3e2e2;
$white: #fefefe;
$white-main: #ffffff;
$gallery: #f4f4f4;
$mine_shaft: #373737;
$dodger-blue: #119bff;
$yellow: #ffd66b;
$red: #e45858;
