@import "../scss/colors.scss";
body {
  font-family: "inter", serif;
  font-size: 20px;
  line-height: 24px;
  * {
    box-sizing: border-box;
  }
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: $white;
}
button {
  border: 0;
  background-color: transparent;
}
.loading-spin {
  animation: spin 1s ease infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
