@import "../scss/sizes";
@import "../scss/colors";
.rtl {
  .language-picker-item-flag {
    margin-left: 10px;
  }
}
.language-picker {
  position: relative;
  z-index: 100000;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
  min-width: 174px;
  background-color: $fun_green;
  border-radius: $border-radius;
  font-weight: 500;
  color: $white;
  &.active {
    ul {
      display: block;
    }
  }
  ul {
    display: none;
    position: absolute;
    background-color: $fun_green;
    top: calc(100% - 5px);
    padding-top: 5px;
    left: 0;
    right: 0;
    max-height: 500px;
    overflow-y: auto;
    z-index: 100000;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $fun_green;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $white;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
p {
  > .language-picker-item-name {
    font-size: 20px;
  }
  > .language-picker-item-flag {
    width: 32px;
  }
}
.language-picker-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  width: 100%;
  padding: 0 8px;
}
.language-picker-item-flag {
  width: 24px;
  border-radius: 50%;
}
.language-picker-item-name {
  margin-left: 12px;
  flex: 1;
  font-size: 15px;
  &.short {
    display: none;
  }
}
.language-picker-item-down {
  margin-right: 4px;
  margin-left: 4px;
  height: 8px;
}

@media only screen and (max-width: 768px) {
  .language-picker {
    min-width: 100px;
  }
  .language-picker-item-name {
    display: none;
    &.short {
      display: block;
    }
  }
}
